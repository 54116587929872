<template>
  <ion-page>
    <ion-content class="maxah-content">
      <div class="top-section relative">
        <div class="slide-container">
          <ion-slides pager="true" :options="slideOptions">
            <ion-slide
              v-for="slideContent in homeSlides"
              :key="slideContent.name"
            >
              <div class="slide-content">
                <div>
                  <ion-text color="mblack" class="h1 ion-text-left">
                    <span>{{ slideContent.name }}</span>
                  </ion-text>
                  <br />
                  <ion-text
                    color="mblack"
                    class="ion-padding-vertical"
                    v-if="slideContent.description"
                  >
                    {{ slideContent.description }}
                  </ion-text>
                  <br />
                  <ion-button
                    class="ion-margin-vertical"
                    v-if="slideContent.link"
                    color="mblack"
                    fill="outline"
                    @click="
                      router.push(
                        slideContent.link + getHash(slideContent.name)
                      )
                    "
                  >
                    Explore More..
                  </ion-button>
                </div>
              </div>
              <div
                class="slide-content-bg relative"
                :style="{ backgroundImage: `url(${slideContent.coverImg})` }"
              ></div>
            </ion-slide>
          </ion-slides>
        </div>
        <div class="introduction">
          <h1 class="h1 ion-margin">What makes us who we are today…</h1>
          <ion-grid>
            <ion-row>
              <ion-col
                size-xs="12"
                size-sm="12"
                size-lg="6"
                size-xl="6"
                size-md="6"
                size="6"
              >
                <div class="ion-margin">
                  <p>
                    At MaXah, we develop innovative and creative products and
                    services that provide total communication and information
                    solutions. Among a plethora of services, web design and
                    development, tailor made applications, ERPs, CRMs,
                    e-commerce solutions, business-to-business applications,
                    business-to-client applications, managed hosting and
                    internet portal management are few that we offer. Satisfied
                    clients around the globe bear testimony to the quality of
                    our work.
                  </p>
                  <p>
                    As a leader in technology exploring, MaXah is committed to
                    exporting quality software worldwide.
                  </p>
                </div>
              </ion-col>
              <ion-col
                size-xs="12"
                size-sm="12"
                size-lg="6"
                size-xl="6"
                size-md="6"
                size="6"
              >
                <div class="ion-margin">
                  <p>
                    The general purpose of MaXah is to develop and promote
                    advanced information technologies for multi-user operation.
                  </p>
                  <p>
                    MaXah's business philosophy is to assure the highest quality
                    product, total client satisfaction, timely delivery of
                    solutions and the best quality/price ratio found in the
                    industry.
                  </p>
                  <p>
                    Our emphasis is on offering a high degree of product user
                    friendliness through a positive, creative and focused
                    company staff.
                  </p>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>

      <div class="middle-section relative">
        <div
          class="vision-mission-bg relative"
          :style="{ backgroundImage: `url('assets/images/contact_bg.jpeg')` }"
        ></div>
        <div class="vision-mission-content">
          <div class="content">
            <h1 class="h1 ion-text-left">
              <span class="main">
                <ion-text color="white" class="elements">
                  <span>Better </span>
                </ion-text>
                <ion-text color="primary" class="elements">
                  <span>solutions</span>
                </ion-text>
                <br />
                <ion-text color="white" class="elements">
                  <span>At your </span>
                </ion-text>
                <ion-text color="primary" class="elements">
                  <span>fingertips</span>
                </ion-text>
                <br />
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div class="introduction">
        <h1 class="h1 ion-margin">Our Mission</h1>
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <div class="description ion-margin">
                <p class="mission">
                  Enhancing business growth of our customers with creative
                  design, development and to deliver market defining high
                  quality solutions that create value and reliable competitive
                  advantage to customers around the globe.
                </p>
                <p class="mission">
                  To deliver optimal solutions with quality and services at
                  reasonable prices. For us customer satisfaction is given top
                  place. We are very friendly in our dealings to the customers
                  and it helps us retain existing clients and expand customer
                  circle. We always try to improve the quality of our products
                  by exploring innovative ideas.
                </p>
                <ul>
                  <li>We value and expect integrity in all that we do</li>

                  <li>
                    We recognize that the foundation of our growth and success
                    is making each and every client project a success
                  </li>
                  <li>
                    We strive for operational and service excellence through a
                    relentless focus on:
                  </li>
                  <ul>
                    <li>
                      People - recruiting, developing and retaining top talent
                    </li>
                    <li>
                      Process – ensuring quality while taking time and cost out
                      of the drug development process
                    </li>
                    <li>
                      Clients – significantly and continually enhancing client
                      satisfaction with our services
                    </li>
                  </ul>
                  <li>
                    We are determined to build a company culture based on
                    superior performance, success through teamwork, respect and
                    recognition for the individual, diverse and challenging
                    work, and flexible and rewarding career opportunities for
                    our employees.
                  </li>
                </ul>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <div class="introduction">
        <h1 class="h1 ion-margin">Our Vision</h1>
        <ion-grid>
          <ion-row class="ion-justify-content-center">
            <ion-col>
              <div class="description ion-margin">
                <p class="mission">
                  To develop in a constant manner and grow as a major IT service
                  provider to become a leading performer, in providing quality
                  Web and Software Development solutions in the competitive
                  global marketplace. Our professional, flexible and integrated
                  process reflects in what we do. We always guide our customers
                  to success. We have the ability to accelerate and quickly
                  share the great work or products of your organization or
                  business.
                </p>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <MaxahDetailedFooter></MaxahDetailedFooter>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/vue";
import MaxahDetailedFooter from "@/components/DetailedFooter.vue";
import { useRoute, useRouter } from "vue-router";
import { SERVICES } from "@/data/data.static";
export default {
  name: "Home",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonSlide,
    IonSlides,
    IonText,
    MaxahDetailedFooter,
  },
  data() {
    const router = useRouter();
    const route = useRoute();
    let subscription: any;
    const homeSlides = SERVICES;
    const slideOptions = {
      loop: true,
      speed: 1000,
      watchSlidesProgress: true,
      autoplay: true,
      initialSlide: Math.floor(Math.random() * homeSlides.length),
    };
    return {
      title: "Home",
      subscription,
      homeSlides,
      router,
      route,
      slideOptions,
    };
  },
  methods: {
    getHash(name) {
      return (
        "#" +
        name
          .replaceAll(/[^a-zA-Z ]/g, "")
          .replaceAll(/\s/g, "")
          .toLowerCase()
      );
    },
  },
};
</script>

<style scoped>
ion-slide {
  height: 90vh;
}

.slide-content,
.vision-mission-content {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-content {
  background-color: rgba(var(--ion-color-mblack-contrast-rgb), 0.3);
}
.slide-content > div {
  max-width: 75%;
  margin: 0 auto;
}
.slide-content-bg,
.vision-mission-bg {
  height: calc(100vh);
  width: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(50%) blur(2px);
}
</style>