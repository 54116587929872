
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/vue";
import MaxahDetailedFooter from "@/components/DetailedFooter.vue";
import { useRoute, useRouter } from "vue-router";
import { SERVICES } from "@/data/data.static";
export default {
  name: "Home",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonSlide,
    IonSlides,
    IonText,
    MaxahDetailedFooter,
  },
  data() {
    const router = useRouter();
    const route = useRoute();
    let subscription: any;
    const homeSlides = SERVICES;
    const slideOptions = {
      loop: true,
      speed: 1000,
      watchSlidesProgress: true,
      autoplay: true,
      initialSlide: Math.floor(Math.random() * homeSlides.length),
    };
    return {
      title: "Home",
      subscription,
      homeSlides,
      router,
      route,
      slideOptions,
    };
  },
  methods: {
    getHash(name) {
      return (
        "#" +
        name
          .replaceAll(/[^a-zA-Z ]/g, "")
          .replaceAll(/\s/g, "")
          .toLowerCase()
      );
    },
  },
};
